<template>
   <div class="content" id="content">
      <canvas v-if="showCanvas"></canvas>
      <div class="noticeText"  v-if="!showCanvas">请长按或截图保存</div>
      <img src="" alt="" id="hideImg">
   </div>
</template>
<script>
export default{
    name:'PageResult',
    loading:false,
    data(){
      return{
        showBottomFont:false,
        showCanvas: true
      }
    },
    mounted(){
      if(localStorage.getItem('surveyResult')){
        this.showBottomFont=document.body.clientHeight/document.body.clientWidth<2.16; 
        this.showResult();
      }else{
        this.$router.replace('/');
      }
    },
    methods:{
      showResult(){
          this.$layer.loading();
          const cacheKey = 'surveyResult';
          let surveyResult = localStorage.getItem(cacheKey) || '[]'; 
          surveyResult  = JSON.parse(surveyResult);
          let firstStepANum = 0;
          let secondStepANum = 0;
          let thirdStepANum = 0;
          for(let i in surveyResult){
            if(surveyResult[i] === 'a'){
              if(i.indexOf('ie') !== -1){
                firstStepANum++; 
              }else if(i.indexOf('jp') !== -1){
                secondStepANum++;
              }else{
                thirdStepANum++;
              }
            }
          }

          const rasultImgList = [
            'http://static.topmh.com/pangu/doctorsurvey/result/21.png',//形式
            'http://static.topmh.com/pangu/doctorsurvey/result/22.png',//神秘
            'http://static.topmh.com/pangu/doctorsurvey/result/23.png',//理性
            'http://static.topmh.com/pangu/doctorsurvey/result/24.jpg',//唯美
            'http://static.topmh.com/pangu/doctorsurvey/result/25.png',//实用
            'http://static.topmh.com/pangu/doctorsurvey/result/26.png',//存在
            'http://static.topmh.com/pangu/doctorsurvey/result/27.png',//斯多葛
            'http://static.topmh.com/pangu/doctorsurvey/result/28.png',//享乐
          ];
          const imgNoticeList = [
            '形式主义','神秘主义','理性主义','唯美主义',
            '实用主义','存在主义','斯多葛主义','享乐主义'
          ];
          let imgIndex = 0;
          if(firstStepANum > 1){
              if(secondStepANum > 1){
                imgIndex = thirdStepANum > 1 ? 0 : 1;
              }else{
                imgIndex = thirdStepANum > 1 ? 2 : 3;
              }
          }else{
            if(secondStepANum > 1){
              imgIndex = thirdStepANum > 1 ? 4 : 5;
            }else{
              imgIndex = thirdStepANum > 1 ? 6 : 7;
            }
          } 
          this.createCanvas(rasultImgList[imgIndex] || rasultImgList[0], imgNoticeList[imgIndex] || imgNoticeList[0]);
        },
        //绘制
        createCanvas(imgUrl, cateName){
          const _that = this;
          const posterWidth = 1080;
          const postHeight = 2873;
          const widthRatio = postHeight/posterWidth;
          const screenWidth = document.body.clientWidth;
          const screenHeight = parseInt(screenWidth * widthRatio, 10);
          let canvas = document.querySelector('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = posterWidth;
          canvas.height = postHeight;
          canvas.style.width = screenWidth;
          canvas.style.height = screenHeight;

          //const img = new Image();
          const img = document.querySelector("#hideImg");
          img.crossOrigin = 'Anonymous';
          //img.src = imgUrl + '?x-oss-process=image/resize,w_'+screenWidth+',m_lfit'; 
          img.src = imgUrl;
          img.setAttribute('crossOrigin', 'anonymous');
          img.style="width:"+screenWidth+"px;height:auto;";
          try{
            img.onload = function(){
              const userName = localStorage.getItem('username');
              ctx.drawImage(img, 0,0, posterWidth, postHeight); 
              ctx.font="50px Arial";
              const title = (userName ? userName + "，您的哲学人格为：" : "您的哲学人格为：") + cateName;
              const text = ctx.measureText(title);
              const textLen = text.width; 
              ctx.fillText(title, (posterWidth - textLen)/2, 110);
              
              setTimeout(function(){
                _that.showImg();
              },1200)

            }
            img.onerror = function(e){
              console.log('失败')
            }
          }catch(e){
            this.$layer.msg('error');
          }
          
        },
        showImg() {
          const image = new Image();
          image.width = document.body.clientWidth;
          image.src = document.querySelector('canvas').toDataURL("image/png"); 
          document.querySelector('#content').prepend(image);
          this.showCanvas = false;
          this.$layer.closeAll();
        }
    }
}
</script>
<style scoped>
  .poster{width:100%;}
  canvas{
    width:100%;
    height:auto;
    overflow:auto;
  }
  .noticeText{
    box-sizing: border-box;
    width: 100vw;
    height: 40px;
    line-height:40px;
    background: rgba(0,0,0,.7);
    color:#999999;
    font-size: 16px;
    text-align: center;
    letter-spacing: 1px;
    user-select: none;
    margin-top: -2px;
  }
  #hideImg{display:none;}
</style>