//1.导入VueRouter
import Vue from "vue";
import VueRouter from 'vue-router'
import Index from "../views/index.vue";

import PageOne from "../views/pageOne.vue";
import PageTwo from "../views/pageTwo.vue";
import PageThree from "../views/pageThree.vue";
import PageFour from "../views/pageFour.vue";
import PageFive from "../views/pageFive.vue";
import PageSix from "../views/pageSix.vue";
import PageSeven from "../views/pageSeven.vue";
import PageEight from "../views/pageEight.vue";
import PageNine from "../views/pageNine.vue";
import PageTen from "../views/pageTen.vue";
import PageEleven from "../views/pageEleven.vue";
import PageTwelve from "../views/pageTwelve.vue";
import PageResult from "../views/pageResult.vue";
//2.使用路由
Vue.use(VueRouter);
//3.创建VueRouter的实例
const router = new VueRouter({
    //tips:不想要 #（锚点）就添加下面代码
     mode:'history', 
    //4.配置路由的path和组件
    routes: [
        {
            path: "/",
            name:'Index',
            component: Index
        },
        {
            path: "/one",
            name:'PageOne',
            component: PageOne,
        },
        {
            path: "/two",
            name:'PageTwo',
            component: PageTwo,
        },
        {
            path: "/three",
            name:'PageThree',
            component: PageThree,
        },
        {
          path: "/four",
          name:'Four',
          component: PageFour,
        },
        {
            path: "/five",
            name:'Five',
            component: PageFive,
        },
        {
            path: "/six",
            name:'Six',
            component: PageSix,
        },
        {
            path: "/seven",
            name:'Seven',
            component: PageSeven,
        },
        {
            path: "/eight",
            name:'Eight',
            component: PageEight,
        },
        {
            path: "/nine",
            name:'Nine',
            component: PageNine,
        },
        {
            path: "/ten",
            name:'Ten',
            component: PageTen,
        },
        {
            path: "/eleven",
            name:'Eleven',
            component: PageEleven,
        },
        {
            path: "/twelve",
            name:'Twelve',
            component: PageTwelve,
        },
        {
            path: "/result",
            name:'result',
            component: PageResult,
        },
      ]
})
//5.导入路由实例
export default router

