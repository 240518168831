<template>
   <div class="content">
      <img src="http://static.topmh.com/pangu/doctorsurvey/assets/12.gif"  class="img"/>
          <img src="http://static.topmh.com/pangu/doctorsurvey/assets/font12.png" alt="" class="img-font">
          <img src="http://static.topmh.com/pangu/doctorsurvey/assets/purple.png" alt="" class="bottom-font" v-if="showBottomFont">
          <div class="button" @click="goLink('a')"></div>
          <div class="button2" @click="goLink('b')"></div>
    </div>
</template>
<script>
export default{
    name:'pageTwelve',
    loading: false,
    data(){
      return{
        showBottomFont:false
      }
    },
    mounted(){
      if(localStorage.getItem('surveyResult')){
        this.showBottomFont=document.body.clientHeight/document.body.clientWidth<2.16;
      }else{
        this.$router.replace('/');
      }
    },
    methods:{
        goLink(value){
          if(this.loading){ 
            return false;
          }
          this.loading = true;
          const cacheKey = 'surveyResult';
          const pageKey = 'tf3';
          let surveyResult = localStorage.getItem(cacheKey) || '[]';
          surveyResult  = JSON.parse(surveyResult);
          surveyResult[pageKey] = value;
          localStorage.setItem(cacheKey,JSON.stringify(surveyResult));
          this.loading = false;
          this.$router.push('/result')
        }
    }
}
</script>
<style scoped>
  .img{
    width: 100%;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  .img-font{
     width: 100%;
    position: relative;

  }
  .img-wrap{
    position: relative;
  }
  .content{
    position: relative;
    height: 100vh;
    overflow: hidden;
   
  }
  .button{
    width:200px;
    height:100px;
    position: absolute;
    top:200px;
    left:20px;
  }
  .bottom-font{
    position: absolute;
    bottom: 15px;
    left: 50%;
    width: 80%;
    transform: translate(-50%,0);
  }
  .button2{
    width:200px;
    height:100px;
    position: absolute;
    top:500px;
    right:20px;
  }
  
</style>