import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import layer from 'vue-layer'
import './style/reset.css'
import 'vue-layer/lib/vue-layer.css';

Vue.config.productionTip = false

Vue.prototype.$layer = layer(Vue);

// (function() {
//   var hm = document.createElement("script");
//   hm.src = "https://hm.baidu.com/hm.js?9d6ff11f761ebdcac29bb119742e3f11";
//   var s = document.getElementsByTagName("script")[0]; 
//   s.parentNode.insertBefore(hm, s);
// })();


router.beforeEach((to,from,next) =>{
  document.title = '医学界的哲学家'
  next();
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

