<template>
  <div id="app">
      <transition  name="slide-left" mode="out-in">
        <router-view :key="$route.fullPath"></router-view>
      </transition>
      <div class="mask" v-if="isPc">
        <div class="noticeArea">
          <div class="tip">请在移动端打开↓↓↓</div>
          <img src="http://static.topmh.com/pangu/doctorsurvey/assets/pageCode.png" alt="" class="code">
        </div>
      </div>
      <audio id="bgMusic" src="http://static.topmh.com/pangu/doctorsurvey/bgaudio.mp3" autoplay preload="metadata" loop></audio>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk';
import axios from 'axios';
export default {
  name: 'App',
  data(){
    return {
      isPc: false
    }
  },
  mounted(){
    
    if((!navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))){
        this.isPc = true;
    }else if(navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger'){
      this.getShareInfo();
    }
  },
  methods:{
    getShareInfo() {
      const shsreUrl = location.href;
      axios.get("http://ttpage.topmh.cn/wx/getShareInfo?url="+encodeURI(location.href))
        .then((rsp) => {
          console.log(rsp.data, 'ad');
          const config = rsp.data ? rsp.data : {};
          if(!config.appId){
            return;
          }
          wx.config({
            appId: config.appId,  // appID 公众号的唯一标识
            timestamp: config.timestamp, // 生成签名的时间戳
            nonceStr: config.nonceStr, //  生成签名的随机串
            signature: config.signature, // 生成的签名
            jsApiList: [
              'updateAppMessageShareData', // 分享到朋友
              'updateTimelineShareData', // 分享到朋友圈
            ]
          });

          wx.ready(() => {
            const shareData = {
              title: '医学界的哲学家',
              desc: '第12届盘古大血管疾病论坛预热：你是具有哪类哲学气质的医生呢？',
              link: shsreUrl, // 分享后的地址
              imgUrl:'http://static.topmh.com/pangu/doctorsurvey/sharelogo1.jpg',
            };
            //点击要去分享
            wx.updateAppMessageShareData(shareData);
            wx.updateTimelineShareData(shareData);
          });
        });
    }
  }
  
}
</script>

<style scoped>
  .mask{
    position: fixed;
    left: 0;
    top: 0;
    width:100%;
    height:100vh;
    background: #1f1c1d;
    z-index:100;
  }
  .noticeArea{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 250px;
    height: 250px;
  }
  .tip{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 15px;
    text-align: center;
    top: -60px;
  }
  .code{
    width:250px;
    height:250px;
  }
</style>
