<template>
   <div class="content">
    <img src="http://static.topmh.com/pangu/doctorsurvey/assets/index.gif"  class="img"/>
    <img src="http://static.topmh.com/pangu/doctorsurvey/assets/index-font.png" alt="" class="img-font">
    <input type="text" class="inputArea" placeholder="请输入名字" maxlength="8" ref="username" @click="openMusic">
    <div class="inputBottomLine"></div>
    <img src="http://static.topmh.com/pangu/doctorsurvey/assets/startbtn.png"  class="goBtn"/>
    <div type="button" class="button" @click="goLink"></div>
       
  
   </div>
</template>
<script>
export default{
    name:'index',
    openMusic: false,
    mounted(){
      window.onresize = () => {
        try{
          if (this.clientHeight > document.documentElement.clientHeight) { 
					document.querySelector('.inputArea').style.bottom = '140px';
          document.querySelector('.goBtn').style.bottom = '15px';
				} else {
					document.querySelector('.inputArea').style.bottom = '175px';
          document.querySelector('.goBtn').style.bottom = '45px';
				}
        }catch(e){}
			};
    },
    methods:{
        openMusic(){
          try{
            document.querySelector("#bgMusic").play();
          }catch(e){}
        },
        goLink(){
          this.openMusic();
          const username = (this.$refs.username.value || '').trim();
          if(username.length < 1){
            this.$layer.msg('请输入名字');
            return;
          }
          localStorage.setItem('username', username);
          this.$router.push('/one'); 
        }
    }
}
</script>
<style scoped>
  .img{
    width: 100%;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }
  .img-wrap{
    position: relative;
  }
  .img-font{
     width: 100%;
    position: relative;

  }
  .content{
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  .button{
    width:200px;
    height:150px;
    position: absolute;
    top:600px;
    left:50%;
    transform: translate(-50%,0);
  }
  .inputArea{
    position:relative;
    width:80%;
    position: absolute;
    bottom:175px;
    left: 50%;
    transform: translate(-50%,0); 
    border: none;
    outline:none;
    color:#1469AE;
    text-align: center;
    background:none;
    font-size:22px;
    z-index:2;
  }
  .inputArea::placeholder{
    color:#1469AE;
  }
  .inputBottomLine{
    width:50%;
    position:absolute;
    bottom:168px;
    left: 50%;
    width:38%;
    transform: translate(-50%,0);
    padding:2px; 
    border-radius:10px;
    background:rgba(255,255,255,0.5); 
  }

  .goBtn{
    position: absolute; 
    bottom: 45px;
    left: 50%;
    width: 80%;
    transform: translate(-50%,0);
  }  
</style>